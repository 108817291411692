
// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// BlocServices1 Module

(function ($) {

    "use strict";

    var self;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function BlocServices1() {

        self = this;

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    BlocServices1.prototype.init = function () {

        var elem = document.querySelector('.services-carousel');

        if( elem ){

            var flkty = new Flickity( elem, {
                'cellAlign': 'left',
                'contain': false,
                'wrapAround': false,
                'prevNextButtons': false,
                'pageDots': false,
                'imagesLoaded': true,
                'draggable': false
            });

        }

    };

    this.BlocServices1 = new BlocServices1();

}.bind(Deckperfo, jQuery)());
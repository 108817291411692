
// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// Hero1 Module

(function ($) {

    "use strict";

    var self;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function Hero2() {

        self = this;

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    Hero2.prototype.init = function () {

        if ($(window).width() > 1000) {

            $(window).scroll(function() {
                var scroll = $(window).scrollTop();
                $(".hero-2").css({
                    backgroundSize: (100 + scroll/30)  + "%",
                    top: -(scroll/30)  + "%",
                });
                $(".overlay-opacity").css({
                    opacity: (0.6 + scroll/900),
                });
            });

        }

    };

    this.Hero2 = new Hero2();

}.bind(Deckperfo, jQuery)());


// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// Custompopup1 Module

(function ($) {

    "use strict";

    var self,
        $overlay,
        $popup,
        $trigger,
        $closer;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function Custompopup1() {

        self        = this;
        $overlay    = $('.overlay');
        $popup      = $('.custom-popup');
        $trigger    = $('[data-trigger-popup]');
        $closer     = $('[data-close-popup]');

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    Custompopup1.prototype.init = function () {

        this.addEventHandler();

    };

    // ------------------------------------
    // EVENT HANDLERS
    // ------------------------------------

    Custompopup1.prototype.addEventHandler = function () {

        self = this;

        $trigger.on('click', function(event) {

            event.preventDefault();

            self.showPopup();

        });

        $closer.on('click', function(event) {

            event.preventDefault();

            self.closePopup();

        });

        $overlay.on('click', function(event) {

            event.preventDefault();

            self.closePopup();

        });

    };

    // ------------------------------------
    // METHODS
    // ------------------------------------

    Custompopup1.prototype.showPopup = function () {

        self = this;

        $overlay.addClass('visible');
        $popup.addClass('visible');

    };

    Custompopup1.prototype.closePopup = function () {

        self = this;

        $overlay.removeClass('visible');
        $popup.removeClass('visible');

    };

    this.Custompopup1 = new Custompopup1();

}.bind(Deckperfo, jQuery)());
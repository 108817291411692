
// Namespacing

window.Deckperfo = window.Deckperfo || {};

// Launch modules

;(function ($) {

  'use strict';

  var app = this;

  $(document).ready(function () {

    // Global js pluggins
    app.BgCoverIt.init();
    app.ScrollToElement.init();

    // Component's core js modules
    app.MainNav1.init();
    // app.Hero2.init();
    app.BlocPublicationShareBtns.init();
    // app.BlocServices1.init();
    // app.BlocInformations1.init();
    // app.BlocTemoignages1.init();
    // app.Custompopup1.init();
    // app.BlocMapContact1.init();
    app.BlocInfolettre1.init();
    app.BlocMapContact2.init();
    app.FooterShareBtns.init();

  });


}.bind(Deckperfo, jQuery)());


// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// BlocInfolettre1 Module

(function ($) {

    "use strict";

    var self;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function BlocInfolettre1() {

        self = this;

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    BlocInfolettre1.prototype.init = function () {

        self = this;

        self.resizeWrapper();

        $(window).resize(function() {
            self.resizeWrapper();
        });

    };

    BlocInfolettre1.prototype.resizeWrapper = function () {
        if ($(window).width() > 1024) {
            var footerHeight = $('#bloc-infolettre').outerHeight();
            $('#site-content').css('padding-bottom', footerHeight + 'px');
        }else{
            $('#site-content').css('padding-bottom', '0');
        }
    };

    this.BlocInfolettre1 = new BlocInfolettre1();

}.bind(Deckperfo, jQuery)());

// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// BlocMapContact2 Module

(function ($) {

    "use strict";

    var self,
        map;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function BlocMapContact2() {

        self = this;
        map = null;

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    BlocMapContact2.prototype.init = function () {

        this.addEventHandler();

        var mapHolder = $('#map-2');

        var app_adress =  $('#contact2 .adresses .bloc-adresse').eq(0).data('marker-adresse');
        var marker_Lat1 = $('#contact2 .adresses .bloc-adresse').eq(0).data('marker-lat');
        var marker_Lng1 =  $('#contact2 .adresses .bloc-adresse').eq(0).data('marker-lng');

        var app_adress_2 =  $('#contact2 .adresses .bloc-adresse').eq(1).data('marker-adresse');
        var marker_Lat2 = $('#contact2 .adresses .bloc-adresse').eq(1).data('marker-lat');
        var marker_Lng2 =  $('#contact2 .adresses .bloc-adresse').eq(1).data('marker-lng');

        var mapSettings = {
            linkToMap : '#',
            pinImage : '/assets/images/google-marker.png',
            pinImage2 : '/assets/images/google-marker.png',
            imageTouch : '/assets/images/google-marker.png'
        };

        var zoomLvl = 12;

        if( $(window).width() <= 699 ){
            zoomLvl = 11;
        }

        if (mapHolder.length) {

            //MAP
            var initialize = function() {
                var mapOptions = {
                    zoom: zoomLvl,
                    scrollwheel: false,
                    overviewMapControl: false,
                    panControl: false,
                    draggable : false,
                    disableDefaultUI: true,
                    rotateControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    zoomControl: true,
                    center: new google.maps.LatLng( marker_Lat1, marker_Lng1 ),
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
                };

                map = new google.maps.Map(document.getElementById('map-2'),mapOptions);

                var myLatLng = new google.maps.LatLng( marker_Lat1, marker_Lng1 );
                var myLatLng2 = new google.maps.LatLng( marker_Lat2, marker_Lng2 );

                var hoffmanMarker = new google.maps.Marker({
                  position: myLatLng,
                  map: map,
                  icon: mapSettings.pinImage
                });

                var hoffmanMarker2 = new google.maps.Marker({
                  position: myLatLng2,
                  map: map,
                  icon: mapSettings.pinImage2
                });

                google.maps.event.addListener(hoffmanMarker, 'click', function () {
                  window.open(mapSettings.linkToMap, '_blank');
                });

                //Retrive the center location
                // google.maps.event.addListener(map, "center_changed", function() {
                //     console.log( map.getCenter().toUrlValue() );
                // });
            };

            google.maps.event.addDomListener(window, 'load', initialize);

        }

    };

    // ------------------------------------
    // EVENT HANDLERS
    // ------------------------------------

    BlocMapContact2.prototype.addEventHandler = function () {

        self = this;

        $('.adresses .bloc-adresse').on('click', function(event) {

            event.preventDefault();

            var myIndex = $(this).index(),
                myAdress = $(this).data('marker-adresse'),
                myLat = $(this).data('marker-lat'),
                myLng = $(this).data('marker-lng');

            $('.adresses .bloc-adresse').removeClass('active');
            $(this).addClass('active');

            map.setCenter(new google.maps.LatLng(myLat, myLng));

            var windowWidth = self.viewport().width;
            if( windowWidth < 768 ){

                var navHeight = $('#main-nav').outerHeight();

                $( 'html, body' ).animate({
                    scrollTop: $( '#contact2' ).offset().top - navHeight
                }, 855);

            }

        });

    };

    // ------------------------------------
    // METHODS
    // ------------------------------------

    BlocMapContact2.prototype.viewport = function () {

        self = this;

         var e = window, a = 'inner';
          if (!('innerWidth' in window)) {
            a = 'client';
            e = document.documentElement || document.body;
          }
          return { width : e[a + 'Width'], height : e[a + 'Height'] };

    };

    this.BlocMapContact2 = new BlocMapContact2();

}.bind(Deckperfo, jQuery)());
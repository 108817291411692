
// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// BlocInformations1 Module

(function ($) {

    "use strict";

    var self;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function BlocInformations1() {

        self = this;

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    BlocInformations1.prototype.init = function () {



    };

    this.BlocInformations1 = new BlocInformations1();

}.bind(Deckperfo, jQuery)());





/**
 * Section Informations Show and Hide articles
 * ===========================================
 *
 */
$(document).ready(function() {

    'use strict';

    var $subjectsLink = $('.subjects-list a'),
        $articles     = $('.subject-content article');

    function init(){

        initEvents();

    }

    function initEvents(){

        $subjectsLink.on('click', function(event) {

            event.preventDefault();

            if( $(this).hasClass('active') ){return false;}

            var windowWidth = viewport().width;
            var article = $(this).data('article');

            $articles.removeClass('active');
            $articles.find('.content').removeClass('active');

            $('.subject-content').find('#' + article).addClass('active');
            setTimeout(function(){$('.subject-content').find('#' + article).find('.content').addClass('active');}, 155);

            $.each( $articles.find('.videoThumb'), function(index, val) {
                callPlayer($(val).data('video'), 'pauseVideo');
                $(val).parents('.videoWrapper').removeClass('hide-it');
            });

            $subjectsLink.removeClass('active');
            $(this).addClass('active');

            if( windowWidth < 768 ){

                var navHeight = $('#main-nav').outerHeight();

                $( 'html, body' ).animate({
                    scrollTop: $( '#subject-content' ).offset().top - navHeight
                }, 855);

            }

        });

    }

    function viewport() {
      var e = window, a = 'inner';
      if (!('innerWidth' in window)) {
        a = 'client';
        e = document.documentElement || document.body;
      }
      return { width : e[a + 'Width'], height : e[a + 'Height'] };
    }

    init();

});
/**
 * Avoid `console` errors in browsers that lack a console.
 * ===========================================
 *
 */
;(function() {
    'use strict';
    var method;
    var noop = function () {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());


// Namespacing

window.Deckperfo = window.Deckperfo  || {};


/**
 *
 * ===========================================
 *
 */



/**
 * BgCoverIt Module
 * ===========================================
 *
 */
(function ($) {

    "use strict";

    var self,
        $bgCoverIt;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function BgCoverIt() {

        self        = this;
        $bgCoverIt  = $('[data-bg-cover-it]');

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    BgCoverIt.prototype.init = function () {

        $.each( $bgCoverIt, function(index, val) {

            var $img = $(val).find('[data-bg-cover-img]'),
                img_src = $img.attr('src');

            $img.remove();

            $(val).css('background-image', 'url("'+img_src+'")');

        });

    };

    this.BgCoverIt = new BgCoverIt();

}.bind(Deckperfo, jQuery)());



/**
 * ScrollToElement Module
 * ===========================================
 *
 */
(function ($) {

    "use strict";

    var self;
    var linkRef;
    var navRef;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function ScrollToElement() {

        self = this;
        linkRef = 'data-scrollto';
        navRef = '#main-nav';

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    ScrollToElement.prototype.init = function () {

        /*goTo*/
        $( '['+linkRef+']' ).on( 'click', function(event){

            event.preventDefault();

            var nextSection = $( this ).attr( linkRef );

            $( 'html, body' ).animate({
                scrollTop: ($( '#'+nextSection ).offset().top) - ($(navRef).outerHeight()) + 1
            }, 855);

        });

    };

    this.ScrollToElement = new ScrollToElement();

}.bind(Deckperfo, jQuery)());



/**
 * Share Btns Popup windows
 * ===========================================
 *
 */
$('.merci .facebook').click(function (event) {
    event.preventDefault();
    window.open($(this).attr("href"), "popupWindow", "width=600,height=600,scrollbars=yes");
});





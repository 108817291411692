
// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// MainNav1 menu

(function ($) {

    "use strict";

    var self,
        burger,
        nav,
        overlay;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function MainNav1() {

        self    = this;
        burger  = '.burger';
        nav     = '#main-nav';
        overlay = '#nav-links-overlay';

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    MainNav1.prototype.init = function () {

        self = this;

        self.addEventHandler();

    };

    // ------------------------------------
    // EVENT HANDLERS
    // ------------------------------------

    MainNav1.prototype.addEventHandler = function () {

        self = this;

        $(burger).on('click', function(event) {
            event.preventDefault();
            $(nav).toggleClass('active');
            $(overlay).toggleClass('active');
            $(burger).toggleClass('active');
        });

        $(overlay).on('click', function(event) {
            event.preventDefault();
            $(nav).removeClass('active');
            $(overlay).removeClass('active');
            $(burger).removeClass('active');
        });

    };

    // ------------------------------------
    // METHODS
    // ------------------------------------

    this.MainNav1 = new MainNav1();

}.bind(Deckperfo, jQuery)());


// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// FooterShareBtns Module

(function ($) {

    "use strict";

    var self;
    var shareBtns;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function BlocPublicationShareBtns() {

        self = this;
        shareBtns = '.shares a';

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    BlocPublicationShareBtns.prototype.init = function () {

        $( shareBtns ).click(function (event) {
console.log('obj');

            event.preventDefault();
            window.open($(this).attr("href"), "popupWindow", "width=600,height=600,scrollbars=yes");
        });

    };

    this.BlocPublicationShareBtns = new BlocPublicationShareBtns();

}.bind(Deckperfo, jQuery)());

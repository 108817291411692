
// Namespacing

window.Deckperfo = window.Deckperfo  || {};


// BlocContactForm1 Module

(function ($) {

    "use strict";

    var self;

    // ------------------------------------
    // CONSTRUCTOR
    // ------------------------------------

    function BlocContactForm1() {

        self = this;

    }

    // ------------------------------------
    // INIT
    // ------------------------------------

    BlocContactForm1.prototype.init = function () {



    };

    this.BlocContactForm1 = new BlocContactForm1();

}.bind(Deckperfo, jQuery)());